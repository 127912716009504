////
/// @group _settings/
////


/// Карта Шрифтов
/// @type {Map}
$font-family: (
	main: 'Rubik-Regular',
	form: sans-serif,
	sub: sans-serif,
	monospace: #{Consolas, "Liberation Mono", Menlo, Courier, monospace}
);
